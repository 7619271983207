import { kyWrapped } from "../lib/utils"

// **** Create an order from the current cart
export const createPayment = (paymentObject, token, logout = () => {}) => {
  const url = `${process.env.GATSBY_WP_URL}/wp-json/wp-sea-saas/v1/payment`
  return kyWrapped(url, {
    json: {
      ...paymentObject,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    logout,
  })
}
