import { useEffect } from "react"
import { navigate } from "gatsby"
import { search } from "../lib/stringHandling"

// **** Listens for a browser message posted to trigger a redirect
export const eventListenerForRedirect = ({ orderData }) => {
  useEffect(() => {
    // Eventlistener will listen for a browser message
    const eventListenerFunction = (event) => {
      let eventData = event.data
      let eventMessage = event.data
      try {
        eventData = JSON.parse(event.data)
      } catch {}
      try {
        eventMessage = eventData.message
      } catch {}

      if (search(eventMessage, /checkout-order-success-complete/) > -1) {
        navigate(buildOrderSummaryUrl(orderData))
      } else if (search(eventMessage, /checkout-close-window/) > -1) {
        navigate(buildOrderPaymentUrl(orderData))
      } else {
        console.log("Unknown eventmessage: ", eventMessage)
      }
    }

    if (typeof window != "undefined") {
      window.addEventListener("message", eventListenerFunction)
      return () => window.removeEventListener("message", eventListenerFunction)
    }
  }, [orderData])
}

// Backup - Watch the iFrame URL and redirect if the URL indicates it as necessary
// NOTE: This code is mainly a fallback which redirects the parent window if the iFrame failed to do so.
export const handleIFrameChange = (orderData, iFrameName, setIframeStatus) => {
  setIframeStatus(true)
  const iFrame = window.document.getElementById(iFrameName)
  let iFrameHref = ""
  // This will throw an exception if the iFrame and parent domains differ
  // If the customer is redirected to /checkout/summary/ inside the iFrame the domains will be identical
  try {
    iFrameHref =
      iFrame &&
      iFrame.contentWindow &&
      iFrame.contentWindow.location &&
      iFrame.contentWindow.location.href
  } catch {}
  if (iFrameHref.includes("/checkout/summary/")) {
    const orderStatusUrl = buildOrderSummaryUrl(orderData)
    navigate(orderStatusUrl)
  }
}

// Convenience function to build a URL to the order payment page
export const buildOrderPaymentUrl = (order) => {
  if (order && order.id) {
    return `/checkout/payment/?orderid=${order.id}&p`
  } else {
    return `/my-account/?&o`
  }
}

// Convenience function to build a URL to the order summary page
export const buildOrderSummaryUrl = (order) => {
  if (order && order.id) {
    return `/checkout/summary/?orderid=${order.id}&p`
  } else {
    return `/my-account/?&o`
  }
}
