import { useState, useContext, useLayoutEffect } from "react"

import CartContext from "../context/cart/cart-context"

import { isBrowser, getQueryVariable } from "../lib/utils"

// Use this hook instead of useState to auto-fetch an order in the query string
// You can still use setOrderData to override the orderData
// Example usage:

// const [orderDataState, setOrderData] = useOrderFetcher({ setApiError })

const useOrderFetcher = ({ setApiError = () => {} }) => {
  const { getOrderById } = useContext(CartContext)
  const [orderData, setOrderData] = useState({})

  // Fetch the order data
  const fetchOrder = async (orderId) => {
    let orderResponse = null
    try {
      orderResponse = await getOrderById(orderId)
      console.log("getOrderById response: ", orderResponse)
    } catch {
      setApiError("An error occurred while fetching your order details.")
    }
    if (orderResponse && orderResponse.id) {
      setApiError("")
      setOrderData(orderResponse)
    } else {
      setApiError(
        (orderResponse && orderResponse.error) ||
          "An error occurred while fetching your order details."
      )
    }
  }

  // If the page loads, check if there is an order to fetch
  // in the query parameters
  useLayoutEffect(() => {
    if (isBrowser()) {
      const orderId = getQueryVariable("orderid")
      if (orderId) {
        fetchOrder(orderId)
      }
    }
  }, [])

  return [orderData, setOrderData]
}

export default useOrderFetcher
