import React from "react"

import PaygatePayment from "../../components/checkout/payment-methods/paygate-payment"
import OzowPayment from "../../components/checkout/payment-methods/ozow-payment"

const PaymentFrame = ({ paymentMethod, paymentData, orderData }) => {
  // This wrapper only exists to make it easier to add more payment methods
  if (paymentMethod == "vcspaygate") {
    return <PaygatePayment paymentData={paymentData} orderData={orderData} />
  }
  if (paymentMethod == "ozow") {
    return <OzowPayment paymentData={paymentData} orderData={orderData} />
  }

  // Fallback
  return null
}

export default PaymentFrame
