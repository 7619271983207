import React, { useState } from "react"
import queryString from "query-string"
import styled from "styled-components"
import {
  eventListenerForRedirect,
  handleIFrameChange,
} from "../../../hooks/checkout-iframe-redirect"
import EllipsisLoader from "../../reusable-components/loaders/elipsis-loader"

const OzowPayment = ({ orderData, paymentData }) => {
  const [iframeStatus, setIframeStatus] = useState(false)

  // This is a React hook that adds an eventListener to redirect when a window message is posted
  // from the payment method iFrame
  eventListenerForRedirect({ orderData })

  // Build Ozow URL if ready
  let url = ""
  if (paymentData && paymentData.SiteCode) {
    // Remove order details from paymentData
    const { order, ...otherPaymentData } = paymentData
    url =
      "https://pay.ozow.com/?viewName=JsPopup&" +
      queryString.stringify(otherPaymentData)
  }

  return paymentData ? (
    <div className="checkout-button">
      <StyledIframe
        id="ozowpaymentFrame"
        src={url}
        scrolling="no"
        frameBorder="0"
        onLoad={() =>
          handleIFrameChange(orderData, "ozowpaymentFrame", setIframeStatus)
        }
      />
    </div>
  ) : (
    <EllipsisLoader
      message="Preparing your payment..."
      height="500px"
      speed={1}
    />
  )
}

export default OzowPayment

// ===============
//     STYLES
// ===============
const StyledIframe = styled.iframe`
  margin-top: 50px;
  width: 100%;
  height: 900px;
  z-index: 2;
  @media (max-width: 768px) {
    height: 1000px;
  }
  @media (max-width: 460px) {
    height: 1050px;
  }
`
