import React, { useContext } from "react"
import styled from "styled-components"

import CartContext from "../../context/cart/cart-context"
import CheckoutContext from "../../context/checkout/checkout-context"

import BlurLoader from "../reusable-components/loaders/blur-loader"
import Grid from "../reusable-components/layout/grid"

import { formatPrice } from "../../lib/utils"
import { getProperty } from "../../lib/utils"

const OrderSummary = ({
  onlyShowOrder = false,
  givenOrder = {},
  showShipping = false,
}) => {
  const { cartContents, cartReady, cartMeta, cartShipping } =
    useContext(CartContext)

  // Prepare variables
  const ordertems = getProperty(givenOrder, ["line_items"], []) || []
  const cartContentsItems = getProperty(cartContents, [], []) || []
  let lineItems = []
  let discountTotal = 0
  let vatTotal = "-"
  let orderTotal = "-"
  let shippingCost = "-"
  let shippingTBC = false
  let shippingTBCMessage = ""
  let shippingWeight = "-"

  // If an order is provided, show order details. Otherwise show cart details
  if (onlyShowOrder || givenOrder?.id) {
    // Prepare order line items
    lineItems = ordertems.map((item) => ({
      title: item.name,
      quantity: item.quantity,
      total: item.total,
    }))

    // Prepare order totals
    vatTotal = getProperty(givenOrder, ["total_tax"], "-")
    orderTotal = getProperty(givenOrder, ["total"], "-")
    shippingCost =
      parseFloat(getProperty(givenOrder, ["shipping_total"], 0)) +
      parseFloat(getProperty(givenOrder, ["shipping_tax"], 0))

    // Check if the order shipping was quoted separately
    if (
      givenOrder?.shipping_data?.meta_data?.some?.(
        (itm) => itm.key == "over_distance" && itm.value == "true"
      )
    ) {
      shippingTBC = true
      shippingTBCMessage = "QUOTED SEPARATELY"
    }

    // Populate shipping weight
    shippingWeight =
      givenOrder?.shipping_data?.meta_data?.find?.((itm) => itm.key == "weight")
        ?.value || "-"
  } else {
    // Prepare cart line items
    lineItems = cartContentsItems.map((item) => ({
      title: item.product_name,
      quantity: item.quantity,
      total: item.price_total,
    }))

    // Prepare cart totals
    discountTotal = getProperty(cartMeta, ["discount"], 0)
    // Before delivery has been calculated, show totals that exclude shipping costs
    if (showShipping) {
      vatTotal = getProperty(cartMeta, ["total_tax"], "-")
      orderTotal = getProperty(cartMeta, ["total"], "-")
      shippingCost = parseFloat(getProperty(cartMeta, ["shipping"], 0))
    } else {
      vatTotal = getProperty(cartMeta, ["subtotal_tax"], "-")
      orderTotal = getProperty(cartMeta, ["subtotal"], "-")
      shippingCost = "-"
    }

    // Check if the order shipping was quoted separately
    if (cartShipping?.meta_data?.over_distance == "true") {
      shippingTBC = true
      shippingTBCMessage = "TO BE QUOTED"
    }

    // Populate shipping weight
    shippingWeight = cartShipping?.meta_data?.weight
  }

  return (
    <BlurLoader
      loading={onlyShowOrder ? !givenOrder.id : !cartReady}
      message="Updating"
    >
      <StyledContainer>
        <StyledH3>Order Summary</StyledH3>
        <ProductsInfoStyling>
          {lineItems.map((item, i) => {
            return (
              <StyledGrid cols="3" key={i} spacing="62% 13% 25%">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                />
                <div>{`x${item.quantity}`}</div>
                <div>{formatPrice({ price: item.total })}</div>
              </StyledGrid>
            )
          })}
        </ProductsInfoStyling>
        {showShipping &&
          !(cartMeta?.selected_shipping_method?.key || "").includes(
            "local_pickup"
          ) && (
            <>
              <VatLine className="nomarg">
                <div>Shipping Cost:</div>
                {shippingTBC ? (
                  <div className="emphasize">{shippingTBCMessage}</div>
                ) : (
                  <div>{formatPrice({ price: shippingCost })}</div>
                )}
              </VatLine>
              <VatLine>
                <div>Shipping Weight:</div>
                <div>{`${shippingWeight} kg`} (estimated)</div>
              </VatLine>
            </>
          )}
        <TotalLine>
          <StyledH3>Grand Total</StyledH3>
          <StyledH3>{formatPrice({ price: orderTotal })}</StyledH3>
        </TotalLine>
        <VatLine>
          <em>Incl. VAT 15%</em>
          <em>{`(${formatPrice({ price: vatTotal })})`}</em>
        </VatLine>
      </StyledContainer>
    </BlurLoader>
  )
}

export default OrderSummary

// ===============
//     STYLES
// ===============
const StyledContainer = styled.div`
  margin: auto;
  max-width: 500px;
  width: 100%;
`
const ProductsInfoStyling = styled.div`
  margin: 2rem 0;
`
const StyledGrid = styled(Grid)`
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  div:first-child {
    font-weight: normal;
    justify-self: left;
  }
  div {
    line-height: 1.5;
    font-weight: bold;
    justify-self: left;
  }
  div:last-child {
    font-weight: bold;
    justify-self: right;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 57% 11% 32%;
  }
`
const TotalLine = styled.div`
  display: flex;
  justify-content: space-between;
`
const VatLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  .emphasize {
    font-weight: bold;
    font-style: italic;
  }
  &.nomarg {
    margin-bottom: 0;
  }
`
const StyledH3 = styled.h3`
  font-weight: 600;
`
