import React, { useContext, useState } from "react"
import styled from "styled-components"

import CartContext from "../../context/cart/cart-context"

import SubmitButton from "../reusable-components/buttons/submit-button"

const ContinueButton = ({
  callback,
  text = "Continue",
  disabled = false,
  className = "",
}) => {
  const { cartReady } = useContext(CartContext)
  return (
    <StyledButton
      text={text}
      callbackFunction={callback}
      type="button"
      disabled={!cartReady || disabled}
      className={className}
    />
  )
}

export default ContinueButton

// ===============
//     STYLES
// ===============
const StyledButton = styled(SubmitButton)`
  margin: auto;
  width: 80%;
`
