import React, { useState, useEffect } from "react"
import EllipsisLoader from "../../reusable-components/loaders/elipsis-loader"
import {
  eventListenerForRedirect,
  handleIFrameChange,
} from "../../../hooks/checkout-iframe-redirect"

const PaygatePayment = ({ orderData, paymentData }) => {
  const [submitted, setSubmitted] = useState(false)
  const [iframeStatus, setIframeStatus] = useState(false)

  // This is a React hook that adds an eventListener to redirect when a window message is posted
  // fromt eh payment method iFrame
  eventListenerForRedirect({ orderData })

  // Once the payment data is ready, show the iFrame
  useEffect(() => {
    if (paymentData && paymentData.pay_request_id) {
      const formElement = document.getElementById("payfastForm")
      if (formElement && typeof formElement.submit === "function") {
        formElement.submit()
        setSubmitted(true)
      }
    }
  }, [paymentData])

  return (
    <div className="checkout-button">
      {!iframeStatus && (
        <EllipsisLoader
          message="Preparing your order..."
          height="500px"
          speed={1}
        />
      )}
      {paymentData &&
      paymentData.payment_url &&
      paymentData.pay_request_id &&
      paymentData.payment_checksum ? (
        <div>
          <iframe
            width="100%"
            height={iframeStatus ? "750" : "0"}
            method="post"
            name="payfastFrame"
            id="payfastFrame"
            onLoad={() =>
              handleIFrameChange(orderData, "payfastFrame", setIframeStatus)
            }
          >
            <p>iframes are not supported by your browser.</p>
          </iframe>
          {!submitted && (
            <form
              action={paymentData.payment_url}
              name="payfastForm"
              id="payfastForm"
              method="post"
              target="payfastFrame"
            >
              <input
                type="hidden"
                name="PAY_REQUEST_ID"
                value={paymentData.pay_request_id}
              />
              <input
                type="hidden"
                name="CHECKSUM"
                value={paymentData.payment_checksum}
              />
              <button type="submitBtnn" value="Click Here" />
            </form>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default PaygatePayment
